import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import '@fortawesome/fontawesome-free/css/all.min.css'
import Menu from './components/modules/Menu';
import { BrowserRouter, Route,Routes } from 'react-router-dom';
import Home from './components/pages/Home';
import Nosotros from './components/pages/Nosotros';
import Contactenos from './components/pages/Contactenos';
import { useEffect } from 'react';
import Footer from './components/modules/Footer';
import FormHeader from './components/modules/FormHeader';
import Servicios from './components/pages/Servicios';
import Eventos from './components/pages/Eventos';
import Capacitaciones from './components/pages/Capacitaciones';
import Social from './components/pages/Social';
import Deporte from './components/pages/Deporte';
import Convenios from './components/pages/Convenios';

function App() {

  
  useEffect(()=>{
    
    document.title = "Coopmulmag";
    //document.body.style.backgroundImage=Fondo;
    });


  return (
    <div className="container bg-light"  >
      
      <BrowserRouter>      
      <Menu></Menu>
      
      <div className='border-top border-3'></div>
      <Routes>
        <Route  path="/nosotros" element={<Nosotros/>} ></Route>
        <Route  path="/servicios" element={<Servicios/>} ></Route>
        <Route  path="/contactenos" element={<Contactenos/>} ></Route>
        <Route  path="/eventos" element={<Eventos/>} ></Route>
        <Route  path="/capacitaciones" element={<Capacitaciones/>} ></Route>
        <Route  path="/convenios" element={<Convenios/>} ></Route>
        <Route  path="/social" element={<Social/>} ></Route>
        <Route  path="/deporte" element={<Deporte/>} ></Route>
        <Route  path="/" element={<Home/>}></Route>
        <Route  path="*" element={<Home/>}></Route>
      </Routes>      
      </BrowserRouter>    
      <hr></hr>  
      <Footer></Footer>
    </div>
  );
}

export default App;
