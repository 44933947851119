import image_01 from './../img/social/202312/01.jpg'
import image_02 from './../img/social/202312/02.jpg'
import image_03 from './../img/social/202312/03.jpg'
import image_04 from './../img/social/202312/04.jpg'
import image_05 from './../img/social/202312/05.jpg'
import image_06 from './../img/social/202312/06.jpg'


export const slides202312 = [
    {
      src: image_01,
      title: '',
      description: '',
    },
    {
      src: image_02,
      title: '',
      description: '',
    },
    {
      src: image_03,
      title: '',
      description: '',
    },
    {
      src: image_04,
      title: '',
      description: '',
    },
    {
      src: image_05,
      title: '',
      description: '',
    },
    {
      src: image_06,
      title: '',
      description: '',
    }
  ]