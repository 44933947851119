import React from 'react';
import { CoordenadaDTO } from "../mapaLeaflet/Coordenada.model";
import DescripcionSitio from "../modules/DescripcionSitio";
import SiderA from '../modules/SiderA';
import SliderHome from '../modules/SliderHome';
import ParallaxUno from '../modules/ParallaxUno';
import ImagenCentral from '../modules/ImagenCentral';
import { GalleryEventos } from '../modules/GalleryEventos';
import ImageModule from '../modules/ProvenzaImage';
import ProvenzaContenido from '../modules/ProvenzaContenido';

export default function Home() {


    //11.2379455, -74.207022
    const coordenadas: CoordenadaDTO[] = [{ lat: 11.2379455, lng: -74.207022 }];

    return (

        <div>


            <div className="me-12  d-lg-none"   >
                <DescripcionSitio></DescripcionSitio>
            </div>


            <div className='row'>
                <div className="col-12 col-sm-8" >
                    <SliderHome />
                </div>
                <div className="col-12 col-sm-4" >
                    <SiderA />
                </div>
            </div>


            <hr></hr>

            

            <ProvenzaContenido />

            
            <hr></hr>
            <ImagenCentral></ImagenCentral>

        </div>

    )

}