

import { slides202312 } from '../img/slide202312';
import { slides202412 } from '../img/slide202412';
import Gallery from '../modules/Gallery';

export default function Social() {

    return (
        <div>
            
            <div className='row'>
                <div className='mt-3'>
                    <h3>Entrega de anchetas 2024</h3>
                    <p>Coopmulmag celebra con sus afiliados la navidad 2024.</p>
                </div>
                <Gallery slides={slides202412} />
            </div>



            <div className='row'>
                <div className='mt-3'>
                    <h3>Entrega de anchetas 2023</h3>
                    <p>Coopmulmag. Está comprometida con la labor social, nuestros cooperados fueron premiados con obsequios y anchetas para celebrar la navidad 2023.</p>
                </div>
                <Gallery slides={slides202312} />
            </div>



        </div>
    )

}