import Banner from './../img/convenios/spa.jpg'



export default function SpaImage(){
    return(
        <div  className="me-12 d- mt-4 text-center"  >         
          <p >
          <img className='d-block w-100' src={Banner} alt='Spa' />    
          </p>
          
        </div>
        
    )
}