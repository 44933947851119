

import Gallery from '../modules/Gallery';
import ImageModule from '../modules/ProvenzaImage';
import ProvenzaContenido from '../modules/ProvenzaContenido';
import SpaContenido from '../modules/SpaContenido';

export default function Convenios() {

    return (
        <div className='row'>
            <ProvenzaContenido />
            <div><hr></hr></div>
            <SpaContenido />
         </div>

    )

}