
import SpaImage from './SpaImage';
export default function SpaContenido() {

    return (


        <div className='row'>
        <div className="col-12 col-sm-6" >
            <SpaImage />
        </div>

        <div className="col-12 col-sm-6" >
        <div className="w-75 mx-auto pt-5" style={{ textAlign: 'justify' }}>
            <h4><span ><strong>Convenio Spa Los Nogales</strong></span></h4>
            <p >Por hacer parte de la familia Coopmulmag, nuestros afiliados recibirán descuentos especiales en Spa Los Nogales:
            </p>
             

        </div>


        </div>
        </div>


        
    )
}