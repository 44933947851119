import image_01 from './../img/social/202412/01.jpg'
import image_02 from './../img/social/202412/02.jpg'
import image_03 from './../img/social/202412/03.jpg'
import image_04 from './../img/social/202412/04.jpg'
import image_05 from './../img/social/202412/05.jpg'


export const slides202412 = [
    {
      src: image_01,
      title: '',
      description: '',
    },
    {
      src: image_02,
      title: '',
      description: '',
    },
    {
      src: image_03,
      title: '',
      description: '',
    },
    {
      src: image_04,
      title: '',
      description: '',
    },
    {
      src: image_05,
      title: '',
      description: '',
    },
  ]